import React, { useState, useEffect } from 'react';
import { AttributionControl, MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import VectorTileLayer from './VectorTileLayer';
import { tlurl } from './utils';
import * as L from 'leaflet';
import { getRegionsAndLatestReports } from './query';
import dayjs from 'dayjs';
import { setStyleForSnowCondition, setStyleForAvalancheCondition, setStyleForFeature, getRegionById } from './utils';

import 'leaflet.vectorgrid';
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import './map.css';


export const regionStyling = {
   "clickable": {
      "stroke": false,
      "fill": true,
      "fillColor": "black",
      "fillOpacity": 0.0
   },
   "mouseOut": {
      "fill": true,
      "color": "black",
      "stroke": false,
      "fillColor": "#990000",
      "fillOpacity": .0      
   },
   "mouseOn": {
      "fill": true,
      "color": "black",
      "stroke": false,
      "fillColor": "#990000",
      "fillOpacity": .9,
   },
   "hidden": {
      "stroke": false,
      "fill": false,
      "fillOpacity": 0.0
   },
   "marked": {
      "fill": true,
      "color": "black",
      "stroke": false,
      "fillColor": "#990000",
      "fillOpacity": .7
   },   
};


const RegionMapGen = (props) => {
   const [regionData, setRegionData] = useState([]);
   const [austriaBorderData, setAustriaBorderData] = useState(null);  // State für GeoJSON-Daten
   const [swissBorderData, setSwissBorderData] = useState(null);  // State für GeoJSON-Daten
   const [germanyBorderData, setGermanyBorderData] = useState(null);  // State für GeoJSON-Daten

   const vectorGridInitializer = (vectorGrid, featureSet, showInfoBox) => {
      vectorGrid.on('click', function (e) {
         featureSet.forEach((val) => { setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut); });
         const reg = getRegionById(regionData, e.sourceTarget.properties.id);
         if (reg) {
            setStyleForFeature(regionData, vectorGrid, e.sourceTarget.properties.id, featureSet, regionStyling.mouseOn);
         }
      });

      vectorGrid.on('load', function (e) {
         featureSet.forEach((val) => {
            setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut);
         });
      });
   };

   useEffect(() => {
      // Abrufen der Region- und Report-Daten
      getRegionsAndLatestReports(dayjs())
         .then(([regions, latestReports]) => {
            setRegionData(regions);
         })
         .catch((error) => {
            console.error('Fehler beim Abrufen der aktuellsten Reports.', error);
         });

      // Abrufen der GeoJSON-Daten für Österreichs Grenze
      fetch('https://nominatim.openstreetmap.org/search?polygon_geojson=1&format=geojson&polygon_threshold=0.005&country=at')
         .then(response => response.json())
         .then(data => {
            setAustriaBorderData(data);  // Speichern der GeoJSON-Daten im State
         })
         .catch(error => {
            console.error('Fehler beim Abrufen der GeoJSON-Daten.', error);
         });
      // Abrufen der GeoJSON-Daten für Österreichs Grenze
      fetch('https://nominatim.openstreetmap.org/search?polygon_geojson=1&format=geojson&polygon_threshold=0.005&country=ch')
         .then(response => response.json())
         .then(data => {
            setSwissBorderData(data);  // Speichern der GeoJSON-Daten im State
         })
         .catch(error => {
            console.error('Fehler beim Abrufen der GeoJSON-Daten.', error);
         });   
      // Abrufen der GeoJSON-Daten für Österreichs Grenze
      fetch('https://nominatim.openstreetmap.org/search?polygon_geojson=1&format=geojson&polygon_threshold=0.005&country=de')
         .then(response => response.json())
         .then(data => {
            setGermanyBorderData(data);  // Speichern der GeoJSON-Daten im State
         })
         .catch(error => {
            console.error('Fehler beim Abrufen der GeoJSON-Daten.', error);
         });                  
   }, []);

   L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

   const url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

   // Funktion zum Setzen des Stils für die Grenze
   const borderStyle = {
      color: 'black',  // Farbe des Strichs
      weight: 5,       // Dicke des Strichs
      opacity: 1,
      fillOpacity: 0
   };

   return (
      <div className={"custom-map-div"}>
         <MapContainer
            center={[47.2, 11.0]}
            zoom={L.Browser.mobile ? 8 : 9}
            style={{ width: '100%', height: '585px' }}
            attributionControl={false}
            className="custom-map"
            scrollWheelZoom={false}
            gestureHandling={true}
         >
            <TileLayer
               url={tlurl}
               attribution='CC by avalanche.report'
            />
            <AttributionControl position="bottomright" prefix={false} />

            {/* GeoJSON für Österreich, wenn die Daten vorhanden sind */}
            {austriaBorderData && (
               <GeoJSON data={austriaBorderData} style={borderStyle} />
            )}
            {swissBorderData && (
               <GeoJSON data={swissBorderData} style={borderStyle} />
            )}
            {germanyBorderData && (
               <GeoJSON data={germanyBorderData} style={borderStyle} />
            )}

            <VectorTileLayer vectorGridInitializer={vectorGridInitializer} regions={regionData} showInfoBox={() => { }} />
         </MapContainer>
      </div>
   );
};

export default RegionMapGen;
