import React from "react";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Container, Row, Col } from "react-bootstrap";
import AvalancheIcon from './AvalancheIcon';
import { avalancheIconLabels } from './utils';

function getAvailableAvalancheConditions(reports) {
   let ret = new Set();
   for (const rid in reports) {
      const ava = reports[rid].avalanche;
      for (const aid in ava) {
         ret.add(ava[aid].problem);
      }
   }
   return ret;
}

const AvalancheIconBar = (props) => {
   const availableConditions = getAvailableAvalancheConditions(props.reports);
   if(availableConditions.size === 0) {
      return <></>;
   }

   return (
      <Container>
         <span style={{ fontSize: "14px" }}>Regionen mit bestimmten Lawinenproblemen anzeigen</span>
         <Row className="justify-content-center">
            <Col xs={12}>
               <Row>
                  <Col xs={6} sm={4} md={2} className="mb-2">
                     <Tooltip title={avalancheIconLabels["newsnow"]}>
                        <Button 
                           onClick={() => props.setAvalancheCond("newsnow")} 
                           color="black" 
                           fullWidth 
                           disabled={!availableConditions.has("newsnow")} >
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                 <AvalancheIcon avalancheType={"newsnow"} style={{ height: "40px", filter: availableConditions.has("newsnow")?"":"grayscale(100%)" }} />
                                 <span style={{ fontSize: "10px" }}>{avalancheIconLabels["newsnow"]}</span>
                              </div>
                        </Button>
                     </Tooltip>
                  </Col>
                  <Col xs={6} sm={4} md={2} className="mb-2">
                     <Tooltip title={avalancheIconLabels["windslab"]}>
                        <Button 
                           onClick={() => props.setAvalancheCond("windslab")}                         
                           color="black" 
                           fullWidth 
                           disabled={!availableConditions.has("windslab")}>
                           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <AvalancheIcon avalancheType={"windslab"} style={{ height: "40px", filter: availableConditions.has("windslab")?"":"grayscale(100%)" }} />
                              <span style={{ fontSize: "10px" }}>{avalancheIconLabels["windslab"]}</span>
                           </div>
                        </Button>
                     </Tooltip>
                  </Col>
                  <Col xs={6} sm={4} md={2} className="mb-2">
                     <Tooltip title={avalancheIconLabels["weaklayer"]}>
                        <Button 
                           onClick={() => props.setAvalancheCond("weaklayer")}
                           color="black" 
                           fullWidth 
                           disabled={!availableConditions.has("weaklayer")}>
                           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <AvalancheIcon avalancheType={"weaklayer"} style={{ height: "40px", filter: availableConditions.has("weaklayer")?"":"grayscale(100%)" }} />
                              <span style={{ fontSize: "10px" }}>{avalancheIconLabels["weaklayer"]}</span>
                           </div>
                        </Button>
                     </Tooltip>
                  </Col>
                  <Col xs={6} sm={4} md={2} className="mb-2">
                     <Tooltip title={avalancheIconLabels["wetsnow"]}>
                        <Button 
                           onClick={() => props.setAvalancheCond("wetsnow")}
                           color="black" 
                           fullWidth 
                           disabled={!availableConditions.has("wetsnow")}>
                           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <AvalancheIcon avalancheType={"wetsnow"} style={{ height: "40px", filter: availableConditions.has("wetsnow")?"":"grayscale(100%)" }} />
                              <span style={{ fontSize: "10px" }}>{avalancheIconLabels["wetsnow"]}</span>
                           </div>
                        </Button>
                     </Tooltip>
                  </Col>
                  <Col xs={6} sm={4} md={2} className="mb-2">
                     <Tooltip title={avalancheIconLabels["glidesnow"]}>
                        <Button 
                           onClick={() => props.setAvalancheCond("glidesnow")}                        
                           color="black" 
                           fullWidth 
                           disabled={!availableConditions.has("glidesnow")}>
                           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <AvalancheIcon avalancheType={"glidesnow"} style={{ height: "40px", filter: availableConditions.has("glidesnow")?"":"grayscale(100%)" }} />
                              <span style={{ fontSize: "10px" }}>{avalancheIconLabels["glidesnow"]}</span>
                           </div>
                        </Button>
                     </Tooltip>
                  </Col>
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default AvalancheIconBar;
