import React, { useState, useEffect } from 'react';
import ScrollToTop from "react-scroll-to-top";
import './Main.css';
import Navigation from './Navigation';
import DateInfoBar from './DateInfoBar';
import CustomMap from './Map';
import Report from './Report';
import { getRegionsAndLatestReports } from './query';
import { setStyleForSnowCondition, setStyleForAvalancheCondition, setStyleForFeature, getRegionById } from './utils';
import { regionStyling } from './regionStyles';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

dayjs.extend(localeData);
dayjs.extend(advancedFormat);
const userLocale = navigator.language || 'de';
dayjs.locale(userLocale);

function Main() {
  const [regionData, setRegionData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [date, setDate] = useState(dayjs());
  const [debouncedDate, setDebouncedDate] = useState(date);
  const [snowCond, setSnowCond] = useState((cond) => () => { });
  const [avalancheCond, setAvalancheCond] = useState((cond) => () => { });

  const vectorGridInitializer = (vectorGrid, featureSet, showInfoBox) => {
    vectorGrid.on('mouseover', function (e) {
      // if the hovered region is known in our database
      if (getRegionById(regionData, e.sourceTarget.properties.id)) {
        // iterate all features and reset to mouseOut
        featureSet.forEach((val) => {
          setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut);
        });
      }
      // set the selected region to mouseOn
      setStyleForFeature(regionData, vectorGrid, e.sourceTarget.properties.id, featureSet, regionStyling.mouseOn);
    });

    vectorGrid.on('mouseout', function (e) {
      // reset the selected region to mouseOut
      setStyleForFeature(regionData, vectorGrid, e.sourceTarget.properties.id, featureSet, regionStyling.mouseOut);
    });

    vectorGrid.on('click', function (e) {
      // reset the styles to mouseOut
      featureSet.forEach((val) => { setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut); });
      // do the click handling
      const reg = getRegionById(regionData, e.sourceTarget.properties.id);
      if (reg) {
        setStyleForFeature(regionData, vectorGrid, e.sourceTarget.properties.id, featureSet, regionStyling.mouseOn);
        showInfoBox(reg);
      } else {
        showInfoBox(null);
      }
    });

    vectorGrid.on('load', function (e) {
      featureSet.forEach((val) => {
        setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut);
      });
    });

    const snowCondSet = (cond) => (cond) => {
      featureSet.forEach((val) => { setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut); });
      setStyleForSnowCondition(reportData, vectorGrid, cond, featureSet, regionStyling.marked);
    }
    setSnowCond(snowCondSet);
    const avalancheCondSet = (cond) => (cond) => {
      featureSet.forEach((val) => { setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut); });
      setStyleForAvalancheCondition(reportData, vectorGrid, cond, featureSet, regionStyling.marked);
    }
    setAvalancheCond(avalancheCondSet);
  };

  const increaseDate = () => {
    const newDate = date.add(1, 'day');
    if (!newDate.isAfter(dayjs())) {
      setDate(newDate);
    }
  };

  const decreaseDate = () => {
    setDate(date.subtract(1, 'day'));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedDate(date);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [date]);

  useEffect(() => {
    getRegionsAndLatestReports(debouncedDate)
      .then(([regions, latestReports]) => {
        const reportsArray = Object.values(latestReports).sort((a, b) => dayjs(b.valid_from).diff(dayjs(a.valid_from)));
        setReportData(reportsArray);
        setRegionData(regions);
        console.log(reportsArray);
        console.log(regions);
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der aktuellsten Reports.', error);
      });
  }, [debouncedDate]);

  return (
    <>
      <div>
        <Navigation />
        <DateInfoBar
          decreaseDate={decreaseDate}
          increaseDate={increaseDate}
          date={date}
          setDate={setDate} />
        <CustomMap
          vectorGridInitializer={vectorGridInitializer}
          regions={regionData}
          reports={reportData} />
        {reportData.length > 0 &&
          <Report
            setSnowCond={snowCond}
            setAvalancheCond={avalancheCond}
            entries={reportData} />
        }
        <ScrollToTop smooth />
      </div>
    </>
  );
}

export default Main;
