export function formatTimestampToUserLocale(timestamp) {
   const date = new Date(timestamp);
   const options = {
      // Optional: 'timeZone': 'Europe/Berlin',
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      //hour: '2-digit',
      //minute: '2-digit',
   };
   return date.toLocaleString(undefined, options);
}

function isWebpSupported() {
   // check webp support.
   var elem = document.createElement('canvas');

   if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
   }
   else {
      // very old browser like IE 8, canvas not supported
      return false;
   }
}

const extension = isWebpSupported() ? ".webp" : ".png";
export const tlurl = "https://static.avalanche.report/tms/{z}/{x}/{y}" + extension;

export const pbfUrl = "https://static.avalanche.report/eaws_pbf/{z}/{x}/{y}.pbf";

export function getRegionById(regions, regionId) {
   for (let region of regions) {
      let regex = new RegExp(region.regex);
      if (regex.test(regionId)) {
         return region;
      }
   }
   return null;
}

export function setStyleForFeature(regions, vectorGrid, sourceFeature, featureSet, styleBase) {
   // hole region mit gegebenem regionscode
   const region = getRegionById(regions, sourceFeature);
   if (region) {
      // markiere alle features für die derselbe regions regex matched
      // und setze den feature style.
      const regex = new RegExp(region.regex);
      featureSet.forEach(function (targetId, index) {
         if (regex.test(targetId)) {
            vectorGrid.setFeatureStyle(targetId.substring(0, 8), styleBase);
         }
      })
   }
}

export function setStyleForSnowCondition(reports, vectorGrid, snowCond, featureSet, styleBase) {
   for (const rid in reports) {
      const report = reports[rid];
      if (report) {
         let hasSnowCondition = false;
         for (const sid in report.snow) {
            hasSnowCondition |= report.snow[sid].snow_type === snowCond;
         }
         if (!hasSnowCondition) {
            // skip region
            continue;
         }
         for (const regId in report.regions) {
            const region = report.regions[regId];
            const regex = new RegExp(region.regex);
            featureSet.forEach(function (targetId, index) {
               if (regex.test(targetId)) {
                  vectorGrid.setFeatureStyle(targetId.substring(0, 8), styleBase);
               }
            })
         }
      }
   }
}

export function setStyleForAvalancheCondition(reports, vectorGrid, avalancheCond, featureSet, styleBase) {
   for (const rid in reports) {
      const report = reports[rid];
      if (report) {
         let hasAvaCondition = false;
         for (const sid in report.avalanche) {
            hasAvaCondition |= report.avalanche[sid].problem === avalancheCond;
         }
         if (!hasAvaCondition) {
            // skip region
            continue;
         }
         for (const regId in report.regions) {
            const region = report.regions[regId];
            const regex = new RegExp(region.regex);
            featureSet.forEach(function (targetId, index) {
               if (regex.test(targetId)) {
                  vectorGrid.setFeatureStyle(targetId.substring(0, 8), styleBase);
               }
            })
         }
      }
   }
}

export const snowIconLabels = {
   powder: 'Pulverschnee',
   firn: 'Firn',
   wet: 'Nassschnee',
   wind: 'Plattenpulver',
   crust: 'Bruchharsch',
   ice: 'Eis',
};

export const avalancheIconLabels = {
   newsnow: 'Neuschnee',
   windslab: 'Triebschnee',
   weaklayer: 'Altschnee',
   wetsnow: 'Nassschnee',
   glidesnow: 'Gleitschnee',
}