import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import dayjs from 'dayjs';

import DatePicker from './DatePicker';

import './dateinfobar.css';

function DateInfoBar(props) {
   const [isDatePickerVisible, setDatePickerVisible] = useState(false);
   const toggleDatePicker = () => {
      setDatePickerVisible(!isDatePickerVisible);
   };

   const setDateAndClose = (val) => {
      props.setDate(val);
      toggleDatePicker();
   }
   const localeFormat = dayjs.localeData().longDateFormat('LL'); 

   return (
      <>
         <Container fluid>
            <div className="date-bar">
               <Row>
                  {/* Linke Spalte (Datum) */}
                  <Col xs={12}>
                     <div className="date-bar-left">
                        {props.date ? props.date.format(localeFormat) : ""}
                        <br style={{pointerEvents: "none", userSelect: "none"}}></br>
                        <IoIosArrowBack className="picker-icon" onClick={props.decreaseDate} />
                        <CiCalendarDate className="picker-icon" onClick={toggleDatePicker} />
                        <IoIosArrowForward className="picker-icon" onClick={props.increaseDate} />
                     </div>
                  </Col>
               </Row>
            </div>
            <Row>
               <Col>
               <div id={"date-picker-div"} style={{ "display": isDatePickerVisible?"block":"none", "position": "absolute", "left": "10px", "backgroundColor": "white", "zIndex": "1050", "border": "1px solid", "marginTop": "5px" }}>
                  <DatePicker setDate={setDateAndClose} value={props.date} />
               </div>
               </Col>
            </Row>
         </Container>
      </>
   );
}

export default DateInfoBar;