import React from "react";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Container, Row, Col } from "react-bootstrap";
import SnowIcon from './SnowIcon';
import { snowIconLabels } from './utils';

function getAvailableSnowConditions(reports) {
   let ret = new Set();
   for (const rid in reports) {
      const snow = reports[rid].snow;
      for (const sid in snow) {
         ret.add(snow[sid].snow_type);
      }
   }
   return ret;
}

const SnowIconBar = (props) => {
   const availableConditions = getAvailableSnowConditions(props.reports);
   if(availableConditions.size === 0) {
      return <></>;
   }

   return (
      <Container>
         <span style={{ fontSize: "14px" }}>Regionen mit bestimmten Schneearten anzeigen</span>
         <Row className="justify-content-center">
            <Col xs={12}>
               <Row>
                  <Col xs={6} sm={4} md={2} className="mb-2">
                     <Tooltip title={snowIconLabels["powder"]}>
                        <Button 
                           onClick={() => props.setSnowCond("powder")} 
                           color="black" 
                           fullWidth 
                           disabled={!availableConditions.has("powder")} >
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                 <SnowIcon snowType={"powder"} size={40} />
                                 <span style={{ fontSize: "10px" }}>{snowIconLabels["powder"]}</span>
                              </div>
                        </Button>
                     </Tooltip>
                  </Col>
                  <Col xs={6} sm={4} md={2} className="mb-2">
                     <Tooltip title={snowIconLabels["firn"]}>
                        <Button 
                           onClick={() => props.setSnowCond("firn")}                         
                           color="black" 
                           fullWidth 
                           disabled={!availableConditions.has("firn")}>
                           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <SnowIcon snowType={"firn"} size={40} />
                              <span style={{ fontSize: "10px" }}>{snowIconLabels["firn"]}</span>
                           </div>
                        </Button>
                     </Tooltip>
                  </Col>
                  <Col xs={6} sm={4} md={2} className="mb-2">
                     <Tooltip title={snowIconLabels["wet"]}>
                        <Button 
                           onClick={() => props.setSnowCond("wet")}
                           color="black" 
                           fullWidth 
                           disabled={!availableConditions.has("wet")}>
                           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <SnowIcon snowType={"wet"} size={40} />
                              <span style={{ fontSize: "10px" }}>{snowIconLabels["wet"]}</span>
                           </div>
                        </Button>
                     </Tooltip>
                  </Col>
                  <Col xs={6} sm={4} md={2} className="mb-2">
                     <Tooltip title={snowIconLabels["wind"]}>
                        <Button 
                           onClick={() => props.setSnowCond("wind")}
                           color="black" 
                           fullWidth 
                           disabled={!availableConditions.has("wind")}>
                           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <SnowIcon snowType={"wind"} size={40} />
                              <span style={{ fontSize: "10px" }}>{snowIconLabels["wind"]}</span>
                           </div>
                        </Button>
                     </Tooltip>
                  </Col>
                  <Col xs={6} sm={4} md={2} className="mb-2">
                     <Tooltip title={snowIconLabels["ice"]}>
                        <Button 
                           onClick={() => props.setSnowCond("ice")}                        
                           color="black" 
                           fullWidth 
                           disabled={!availableConditions.has("ice")}>
                           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <SnowIcon snowType={"ice"} size={40} />
                              <span style={{ fontSize: "10px" }}>{snowIconLabels["ice"]}</span>
                           </div>
                        </Button>
                     </Tooltip>
                  </Col>
                  <Col xs={6} sm={4} md={2} className="mb-2">
                     <Tooltip title={snowIconLabels["crust"]}>
                        <Button 
                           onClick={() => props.setSnowCond("crust")}
                           color="black" 
                           fullWidth 
                           disabled={!availableConditions.has("crust")}>
                           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <SnowIcon snowType={"crust"} size={40} />
                              <span style={{ fontSize: "10px" }}>{snowIconLabels["crust"]}</span>
                           </div>
                        </Button>
                     </Tooltip>
                  </Col>
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default SnowIconBar;
