import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './Main';
import RegionMapGen from './RegionMapGen';

const App = () => {
   return (
      <Router>
         <Routes>
            <Route path="/regionmapgen" exact element={<RegionMapGen />} />
            <Route path="/" exact element={<Main />} />
            <Route path="*" element={<Main />} />
         </Routes>
      </Router>
   );
};

export default App;