import React, { useEffect, useRef } from 'react';

import './report.css';

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; 
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const lumaDarkenOnly = (bottomPixel, topPixel) => {
  const bottomLuma = 0.299 * bottomPixel[0] + 0.587 * bottomPixel[1] + 0.114 * bottomPixel[2];
  const topLuma = 0.299 * topPixel[0] + 0.587 * topPixel[1] + 0.114 * topPixel[2];

  // Überprüfen, ob der Blauanteil von topPixel hoch ist
  return topLuma < bottomLuma ? [...topPixel] : [...bottomPixel];
};

const mergeImages = (canvas, images) => {
  const ctx = canvas.getContext('2d');
  
  // Set canvas dimensions based on the first image size
  canvas.width = images[0].width;
  canvas.height = images[0].height;

  const { width, height } = canvas;

  ctx.clearRect(0, 0, width, height);
  ctx.drawImage(images[0], 0, 0, width, height);

  let baseImageData = ctx.getImageData(0, 0, width, height);

  for (let i = 1; i < images.length; i++) {
    ctx.clearRect(0, 0, width, height);
    ctx.drawImage(images[i], 0, 0, width, height);

    const topImageData = ctx.getImageData(0, 0, width, height);
    const resultImageData = ctx.createImageData(width, height);

    for (let j = 0; j < baseImageData.data.length; j += 4) {
      const bottomPixel = [
        baseImageData.data[j],
        baseImageData.data[j + 1],
        baseImageData.data[j + 2],
        baseImageData.data[j + 3]
      ];

      const topPixel = [
        topImageData.data[j],
        topImageData.data[j + 1],
        topImageData.data[j + 2],
        topImageData.data[j + 3]
      ];

      const [r, g, b, a] = lumaDarkenOnly(bottomPixel, topPixel);

      resultImageData.data[j] = r;
      resultImageData.data[j + 1] = g;
      resultImageData.data[j + 2] = b;
      resultImageData.data[j + 3] = a;
    }

    baseImageData = resultImageData;
  }

  ctx.putImageData(baseImageData, 0, 0);
};

const RegionImageOverlay = ({ imageSources }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const loadAndMergeImages = async () => {
      try {
        const images = await Promise.all(imageSources.map(src => loadImage(src)));

        // Set the canvas size to match the first image's dimensions
        canvas.width = images[0].width;
        canvas.height = images[0].height;

        mergeImages(canvas, images);
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    if (canvas) {
      loadAndMergeImages();
    }
  }, [imageSources]);

  return (
    <canvas ref={canvasRef} className={"region-image"} />
  );
};

export default RegionImageOverlay;
