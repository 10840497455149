import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import dayjs from 'dayjs';

import { fetchReportDates } from './query';

function ServerDay(props) {
  const { highlightedDays = [], day, ...other } = props;
  const isHighlighted = props.highlightedDays.some(date => date.isSame(props.day, 'day'));

  return (
    <PickersDay 
      {...other} 
      sx={{ 
        border: isHighlighted ? "2px solid #b84d4d" : "",
      }} 
      day={day} 
    />
  );
}

export default function DatePicker(props) {
  const [highlightedDays, setHighlightedDays] = useState([]);

  useEffect(() => {
    fetchReportDates()
      .then((reports) => {
        const reportsArray = Object.values(reports);
        const dateSet = new Set();
        for (const r in reportsArray) {
          const rep = reportsArray[r];
          dateSet.add(dayjs(rep.valid_from));
        }
        console.log(dateSet);
        setHighlightedDays(Array.from(dateSet));
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der Reportdaten.', error);
      });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        disableFuture={true}
        value={props.value}
        onChange={(newValue) => {
          props.setDate(newValue);
        }}
        sx={{
          "& .MuiPickersDay-root": {
            "&.Mui-selected": {
              backgroundColor: "#b84d4d",
            },
          },
        }}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          },
        }}
      />
    </LocalizationProvider>
  );
}