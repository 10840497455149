import { React, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import Carousel from 'react-bootstrap/Carousel';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';

import SnowConditionEntry from './SnowConditionEntry';
import AvalancheConditionEntry from './AvalancheConditionEntry';
import RegionImageOverlay from './RegionImageOverlay';
import { dbEndpoint } from './query';
import { formatTimestampToUserLocale } from './utils';
import { PiSnowflakeLight } from "react-icons/pi";
import { CgDanger } from "react-icons/cg";

import './report.css';

const ReportPaperElem = styled(Paper)(({ theme }) => ({
   width: "90%",
   margin: "20px",
   padding: theme.spacing(2),
   ...theme.typography.body2,
   textAlign: 'center',
}));

function ReportPaper({ entry }) {
   const [index, setIndex] = useState(0);

   const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
   };

   return (
      <>
         <div id={entry.documentId} style={{ scrollMarginTop: "50px" }}></div>
         <ReportPaperElem elevation={6}>
            <div className="report-box">
               <Grid container >
                  <Grid container size={{ xs: 12, md: 6 }} className="d-flex align-items-center justify-content-center">
                     <Grid size={{ xs: 12 }} className="d-flex align-items-start justify-content-center" >
                        <h3 className="report-heading">Schneereport für {formatTimestampToUserLocale(entry.valid_from)}</h3>
                     </Grid>
                     <Grid size={{ xs: 12 }} >
                        <h3 className='report-title'>{entry.title}</h3>
                        <div className="d-flex align-items-center justify-content-center">
                           {entry.regions.map((region, idx) => (
                              <Chip style={{ margin: "10px" }} variant="outlined" label={region.name} />
                           ))}
                        </div>
                     </Grid>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }} className="d-flex justify-content-center">
                     <RegionImageOverlay imageSources={entry.regions.map((reg, idx) => {
                        return dbEndpoint + reg.regionImage.url;
                     })}
                     />
                  </Grid>

                  <Grid size={{ xs: 12 }} >
                     <hr style={{ border: '1px solid black', opacity: '.5' }} />
                  </Grid>
                  <Grid size={{ xs: 12, md: 12 }} className="d-flex align-items-center justify-content-center">
                     <div style={{ width: "100%" }}>
                        <Grid container >
                           {!!entry.snow && !!entry.snow.length &&
                              <>
                                 <Grid size={{ xs: 1, md: 1 }} className="d-flex align-items-center justify-content-center">
                                    <div className="d-flex align-items-center justify-content-center snow-grid">
                                       <Chip
                                          sx={{
                                             transform: 'rotate(-90deg)',
                                             transformOrigin: 'center',
                                             minWidth: '100px',
                                             backgroundColor: "#e0b3b3"
                                          }}
                                          icon={<PiSnowflakeLight size={"20px"} />}
                                          label="Schnee"
                                          size="string"
                                       />
                                    </div>
                                 </Grid>
                                 <Grid size={{ xs: 11, sm: 11 }} >
                                    {Object.entries(entry.snowData).map(([snowCondition, snowConditionData], idx) => (
                                       <SnowConditionEntry
                                          isFirstEntry={idx === 0}
                                          snowType={snowCondition}
                                          snowConditionData={snowConditionData}
                                          cssIdentifier={entry.documentId+"-s"+idx}
                                       />
                                    ))}
                                 </Grid>
                                 <Grid size={{ xs: 12 }} style={{ padding: '0px', marginTop: '10px' }}>
                                    <hr style={{ opacity: '.5', border: '1px solid black', margin: '0 0 10px 0' }} />
                                 </Grid>

                              </>
                           }
                           {!!entry.avalanche && !!entry.avalanche.length &&
                              <>
                                 <Grid size={{ xs: 1, md: 1 }} className="d-flex align-items-center justify-content-center">
                                    <div className="d-flex align-items-center justify-content-center avalanche-grid">
                                       <Chip
                                          sx={{
                                             transform: 'rotate(-90deg)',
                                             transformOrigin: 'center',
                                             minWidth: '100px',
                                             backgroundColor: "#ebd3f2",
                                          }}
                                          icon={<CgDanger size={"20px"} />}
                                          label="Lawinen"
                                          size="string"
                                       />
                                    </div>
                                 </Grid>
                                 <Grid size={{ xs: 11, sm: 11 }} >
                                    {Object.entries(entry.avalancheData).map(([avalancheCondition, avalancheConditionData], idx) => (
                                       <AvalancheConditionEntry
                                          isFirstEntry={idx === 0}
                                          avalancheType={avalancheCondition}
                                          avalancheConditionData={avalancheConditionData}
                                          cssIdentifier={entry.documentId+"-a"+idx}
                                       />
                                    ))}
                                 </Grid>
                                 <Grid size={{ xs: 12 }} style={{ padding: '0px', marginTop: '10px' }}>
                                    <hr style={{ opacity: '.5', border: '1px solid black', margin: '0 0 10px 0' }} />
                                 </Grid>

                              </>
                           }
                        </Grid>
                     </div>
                  </Grid>
               </Grid>
            </div>

            <div className='report-text'>
               <ReactMarkdown>{entry.content}</ReactMarkdown>
               <div className='report-images'>
                  <Carousel controls={true} activeIndex={index} onSelect={handleSelect}>
                     {entry.images.map((image, idx) => (
                        <Carousel.Item key={idx}>
                           <img
                              src={dbEndpoint + image.url}
                              alt={`Bild ${idx + 1}`}
                              style={{ width: '100%' }}
                           />
                        </Carousel.Item>
                     ))}
                  </Carousel>
               </div>
            </div>
         </ReportPaperElem >
      </>);
}

export default ReportPaper;
